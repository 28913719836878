import styles from "./News.module.scss";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import getRequest from "../../../../helpers/getRequest";
import tempImg from "../../../../img/temp/00.jpg";
import tempImg2 from "../../../../img/temp/temp-new0.png";
import tempImg3 from "../../../../img/temp/temp3-0.png";
const News = ({ currentLang }) => {
  const [news, setNews] = useState([]);
  const PER_PAGE = 2;
  const PAGE = 1;
  const { t } = useTranslation();
  useEffect(() => {
    try {
      getRequest(`v1/articles`, null, currentLang.lang).then(
        async (response) => {
          const data = await response.data;
          // Get only the most recent article
          setNews(data.articles.slice(0, 1));
        }
      );
    } catch (err) {
      console.log(err);
    }
  }, [currentLang]);

  return (
    <section className={styles.news}>
      <div className="container">
        <h2>{t("Recent news")}</h2>
        <div className={styles.news__grid}>
          {news &&
            news.length !== 0 &&
            news.map((n, i) => (
              <div className={styles.card} key={n.id}>
                <a href={`/news/${n.id}`}>
                  <div
                    className={styles.card__main}
                    style={{
                      backgroundImage: `url(${n.image})`,
                      backgroundSize: "cover",
                    }}
                  ></div>
                  <div className={styles.card__overlay}>
                    <h5>{n.title}</h5>
                    <p
                      dangerouslySetInnerHTML={{ __html: n.title + "..." }}
                    ></p>
                    <span>
                      {currentLang.lang == "EN"
                        ? new Date(n.created_at).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })
                        : new Date(n.created_at).toLocaleDateString("de-DE", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                    </span>
                  </div>
                </a>
              </div>
            ))}
          <div className={styles.card}>
            <a href="/news/31">
              <div
                className={styles.card__main}
                style={{
                  backgroundImage: `url(${tempImg3})`,
                  backgroundSize: "100%",
                }}
              ></div>
              <div className={styles.card__overlay}>
                <h5>
                  {t(
                    "First cannabis club from Hanover to start cultivation soon"
                  )}
                </h5>
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      t(
                        "After months of preparation, we are pleased to announce"
                      ) + " ...",
                  }}
                ></p>
                <span>
                  {currentLang.lang == "EN"
                    ? "February 4, 2025"
                    : "4. Februar 2025"}
                </span>
              </div>
            </a>
          </div>
          <div className={styles.card}>
            <a href="/news/30">
              <div
                className={styles.card__main}
                style={{ backgroundImage: `url(${tempImg2})` }}
              ></div>
              <div className={styles.card__overlay}>
                <h5>{t("Presentation of the DAG-GROW cultivation module")}</h5>
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      t("On November 30, 2024, the German cultivation") +
                      " ...",
                  }}
                ></p>
                <span>
                  {currentLang.lang == "EN"
                    ? "December 5, 2024"
                    : "5. Dezember 2024"}
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default News;
